.file-upload-main-div {
    width: 100%;
    .filedrop {
        border: 1px solid #d3d3d3;
        text-align: center;
        padding: 0.5rem;
        border-radius: 4px;
        min-width: 160px;
        display: flex;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;
        .text-box {
            padding: 12px 0.5rem;
            display: flex;
            flex-direction: column;
            p {
                margin: 0px;
            }
            .only {
                font-size: 12px;
                font-weight: 600;
                text-transform: capitalize;
            }
        }
        .text {
            span {
                font-size: 12px;
            }
        }
    }

    .filedrop.drag {
        border: 2px dashed #1e90ff;
    }

    .browse {
        color: white;
        text-transform: none;
        padding: 0px 0.5rem;
    }
    .file-input {
        display: none;
    }
    .file {
        padding: 0.5rem 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        width: fit-content;
        button {
            position: absolute;
            top: 0px;
            right: 0px;
        }
    }
}
