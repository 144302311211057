.hack-details {
    display: flex;
    flex-direction: column;
}

.confirmation-child {
    margin-top: 1rem;
    width: 100%;
    .reason-area {
        width: 100%;
    }
}

.hack-details .content {
    display: flex;
    flex-direction: column;
    margin: 0 138px;
    margin-bottom: 100px;
}

.hack-details .content .source-container {
    background: #e6e6e6;
    height: 500px;
    margin-top: 50px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hack-details .content .source-container #presentation-comp {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 250px;
}

.hack-details .content .source-container #presentation-comp div {
    display: flex;
    flex-direction: column;
}

.hack-details .content .source-container video {
    width: 100%;
    height: 100%;
}

.hack-details .content .source-container img {
    max-width: 100%;
    max-height: 100%;
}

.hack-details .content .source-container .side-tiles {
    width: 60px;
    height: 100%;
    position: absolute;
    right: -30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@keyframes sidetilehover {
    from {
        height: 60px;
        width: 60px;
    }
    to {
        height: 70px;
        width: 70px;
    }
}

.hack-details .content .source-container .side-tiles .tile:hover {
    animation-name: sidetilehover;
    animation-duration: 0.4s;
    height: 70px;
    width: 70px;
}

.hack-details .content .title-cntr {
    display: flex;
    align-items: center;
    margin-top: 30px;
    justify-content: space-between;
    font-family: 'Playfair Display', Regular;
}

.hack-details .content .title-cntr .title-text {
    font-size: 26px;
    font-weight: bold;
    color: #22bca8;
}

.hack-details .content .title-cntr .team-name {
    font-size: 20px;
    margin-top: 10px;
}

.hack-details .content .title-cntr .git-url,
.hack-details .content .title-cntr .catg,
.hack-details .content .title-cntr .loc {
    margin-top: 30px;
    color: #343434;
    font-weight: bold;
    font-size: 14px;
    border: 1px dashed #d5d5d5;
    padding: 10px 20px;
    box-shadow: 0 0 30px #f5f5f5;
    width: fit-content;
}

.hack-details .content .title-cntr .git-url:before {
    content: 'Source Code';
    font-size: 9px;
    color: #464242;
    margin-right: 10px;
    text-transform: uppercase;
    font-weight: 300;
}

.hack-details .content .title-cntr .catg:before {
    content: 'Category';
    font-size: 9px;
    color: #464242;
    margin-right: 10px;
    text-transform: uppercase;
    font-weight: 300;
}

.hack-details .content .title-cntr .loc:before {
    content: 'Location';
    font-size: 9px;
    color: #464242;
    margin-right: 10px;
    text-transform: uppercase;
    font-weight: 300;
}

/*.hack-details .content .title-cntr .git-url {
      margin-top: 16px;
      border: solid 1px #f0edf700;
      color: #696969;
      font-weight: 500;
      font-size: 13px;
      background-color: #00ffde1a;
      border-radius: 7px;
      font-family: 'Montserrat', sans-serif;
      width: fit-content;
  }
  
  .hack-details .content .title-cntr .git-url {
      padding: 10px;
  }*/

.hack-details .content .title-cntr .git-url input {
    box-sizing: border-box;
    color: grey;
    font-size: 13px;
    width: 100%;
    background: transparent;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    outline: none;
    font-family: 'Montserrat', sans-serif;
}

.hack-details .content .title-cntr .title-team {
    display: flex;
    flex-direction: column;
}

.hack-details .content .title-cntr .likes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hack-details .content .title-cntr .likes .vote-btn[disable='true'] {
    cursor: default;
}

.hack-details .content .title-cntr .likes .like-count {
    font-size: 16px;
    margin-top: 10px;
    white-space: nowrap;
}

.hack-details .content .main-cntr {
    display: flex;
}

.hack-details .content .main-cntr .left {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.hack-details .content .main-cntr .left .rating-wrap {
    display: flex;
    padding: 10px 20px;
    margin: 20px 0;
    flex-direction: column;
    width: 100%;
    padding: 0 30px;
}

.hack-details .content .main-cntr .left .rating-wrap .my-rating {
    display: flex;
    align-items: center;
    padding: 0 50px;
}

.hack-details .content .main-cntr .left .rating-wrap .my-rating .inputs {
    display: flex;
    flex: 1;
}

.hack-details .content .main-cntr .left .rating-wrap .my-rating .avrg {
    width: 50px;
    height: 30px;
    font-size: 13px;
    color: #555;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    background: #f5f5f5;
    box-shadow: 0 0 50px #f9f9f9;
    border: 1px solid #e0e0e0;
    margin-left: 30px;
    padding: 10px;
    font-weight: 500;
}

.hack-details .content .main-cntr .left .rating-wrap .my-rating .avrg label {
    font-size: 8px;
    margin-top: 5px;
    font-weight: 300;
}
.hack-details .content .main-cntr .left .rating-wrap .all-ratings {
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
}

.hack-details .content .main-cntr .left .rating-wrap .all-ratings .eachel {
    display: flex;
    margin: 2px;
    padding: 10px;
    background: #f5f5f5;
    box-shadow: 0 0 50px #f9f9f9;
    border: 1px solid #e0e0e0;
}

.hack-details .content .main-cntr .left .rating-wrap .all-ratings .eachel .descr {
    padding: 10px;
    flex: 1;
}

.hack-details .content .main-cntr .left .rating-wrap .all-ratings .eachel .descr p {
    white-space: pre-wrap;
    text-align: justify;
}

.hack-details .content .main-cntr .left .rating-wrap .all-ratings .eachel .rate {
    width: 30px;
    height: 30px;
    padding: 10px;
    font-size: 13px;
    color: #555;
    justify-content: center;
    align-items: center;
    display: flex;
    background: #f9f9f9;
    box-shadow: 0 0 50px #f9f9f9;
    border: 1px solid #e5e5e5;
}

.hack-details .content .main-cntr .right {
    width: 206px;
    height: fit-content;
    margin-left: 60px;
}

.hack-details .content .main-cntr .right h2 {
    font-size: 20px;
    font-family: 'Playfair Display', Regular;
}

.hack-details .content .main-cntr .right .box {
    width: 206px;
    height: 150px;
    margin-top: 20px;
    background: #fcfcfc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.hack-details .content .main-cntr .right .box .image {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background: #dfd9d9;
}

.hack-details .content .main-cntr .right .box .image img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background: #dfd9d9;
}

.hack-details .content .main-cntr .right .box .name {
    font-weight: bold;
    margin-top: 10px;
}

.hack-details .content .main-cntr .right .box .email {
    margin-top: 5px;
}

.hack-details .content .main-cntr .left .desc {
    margin-top: 40px;
    font-size: 16px;
    line-height: 30px;
    text-align: justify;
    max-width: 100%;
    overflow-wrap: break-word;
}

.hack-details .content .main-cntr .left .admin {
    width: 100%;
    min-height: 100px;
    box-shadow: 0 0 55px #cccccc29;
    margin-top: 30px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    /*font-family: "Playfair Display", Regular;*/
    align-items: center;
    max-width: 100%px;
    flex-direction: column;
    padding: 30px;
}

.hack-details .content .main-cntr .left .admin .mark-label {
    width: 100%;
    text-align: left;
}

.hack-details .content .main-cntr .left .admin span {
    font-size: 16px;
    font-weight: bold;
    margin: 16px 0;
    font-family: 'Playfair Display', Regular;
}

.hack-details .content .main-cntr .left .admin .multipleSelect {
    padding: 0 30px;
}

.hack-details .content .main-cntr .left .admin .fstResultItem {
    font-size: 12px;
    font-weight: bold;
    color: teal;
    padding: 10px 10px;
    background: #ccc;
    margin: 0;
    border: none;
    font-family: sans-serif;
}

.hack-details .content .main-cntr .left .admin .fstResultItem:hover {
    background: #bbb;
}

.hack-details .content .main-cntr .left .admin .fstSelected {
    background: #52a2f3;
    color: white;
}

.hack-details .content .main-cntr .left .admin .btn {
    margin: 2px 7px;
    font-family: 'Playfair Display', Regular;
}

.hack-details .content .main-cntr .left .comments {
    margin-top: 40px;
}

.hack-details .content .main-cntr .left .comments .add-cmnt {
    display: flex;
    font-family: 'Playfair Display', Regular;
    justify-content: flex-end;
    font-size: 12px;
}

.hack-details .content .main-cntr .left .comments .add-cmnt .btn-green {
    margin-left: 5px;
}

.hack-details .content .main-cntr .left .comments h2 {
    font-size: 20px;
    font-family: 'Playfair Display', Regular;
    margin-bottom: 30px;
}

.hack-details .content .main-cntr .left .comments .cmnt-item {
    display: flex;
    padding-bottom: 10px;
    border-bottom: 1px solid #f5f5f5;
    margin-bottom: 15px;
}

.hack-details .content .main-cntr .left .comments .cmnt-item .image-cntr .image {
    width: 50px;
    height: 50px;
    background: #ecf0f1;
    border-radius: 50%;
    margin-right: 20px;
}

.hack-details .content .main-cntr .left .comments .cmnt-item .image-cntr .image img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
}

.hack-details .content .main-cntr .left .comments .cmnt-item .cmnt-details {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.hack-details .content .main-cntr .left .comments .cmnt-item .cmnt-details textarea {
    padding: 20px;
    height: 100px;
    width: 100%;
    border: 1px solid #e8e8e8;
}

.hack-details .content .main-cntr .left .comments .cmnt-item .cmnt-details .name {
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: bold;
}

.hack-details .content .main-cntr .left .comments .cmnt-item .cmnt-details .name span {
    margin-left: 5px;
    color: #22bca8;
}

.hack-details .content .main-cntr .left .comments .cmnt-item .cmnt-details .value {
    font-size: 12px;
    line-height: 25px;
    text-align: justify;
}
.fstElement {
    width: 100%;
}
.fstToggleBtn {
    min-width: 16.5em;
}
.submitBtn {
    display: none;
}
.fstMultipleMode {
    display: block;
}
.fstMultipleMode .fstControls {
    width: 100%;
}
.fstResultItem {
    font-size: 10px;
    color: teal;
    padding: 5px 10px;
}

.hack-details .content .main-cntr .left .rating-wrap .rating-comment {
    display: flex;
    align-items: center;
    padding: 0 50px;
}

.hack-details .content .main-cntr .left .rating-wrap .rating-comment .rating-comment-box {
    padding: 10px;
    width: 100%;
}

.hack-details .content .main-cntr .left .rating-wrap .rating-comment .rating-comment-box textarea {
    width: 100%;
    height: 60px;
    margin-bottom: 15px;
    border: 1px solid #e8e8e8;
    padding: 20px;
    padding-left: 30px;
    font-size: 14px;
}

.hack-details .content .main-cntr .left .rating-wrap .rating-comment .rating-submit-button button {
    color: #50e3c2;
    background: white;
    border: 1px solid #d4d4d4;
}
