.upload-or-browse-main-div {
    width: 100%;
    a {
        font-size: 1rem;
    }
    .filedrop {
        background: #ffffff;
        border: 1px solid #cccccc;
        text-align: center;
        padding: 0.5rem;
        border-radius: 4px;
        height: 1.6rem;
        min-width: 160px;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        .text-box {
            padding: 12px 0.5rem;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            gap: 1rem;

            .upload {
                font-size: 1rem;
                span {
                    color: #31baa6;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }

    .browse {
        text-transform: none;
        padding: 0px 0.5rem;
    }
    .file-input {
        display: none;
    }
    .file {
        padding: 0.5rem 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        width: fit-content;

        button {
            position: absolute;
            top: 0px;
            right: 0px;
        }

        .display {
            width: 50px;
        }
        &.media {
            flex-direction: row;
            gap: 0.5rem;
            width: 100%;
            button {
                position: static;
            }
            .flex1 {
                display: flex;
                flex: 1;
                justify-content: flex-end;
            }
        }
        .loading {
            text-decoration: none;
            color: #333333;
        }
        .document-link {
            color: #31baa6;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .loader {
        width: fit-content;
        font-weight: 500;
        font-family: sans-serif;
        font-size: 15px;
        padding: 0 5px 8px 0;
        margin-left: 0.2rem;
        background: repeating-linear-gradient(90deg, currentColor 0 8%, #0000 0 10%) 200% 100%/200%
            2px no-repeat;
        animation: l3 2s steps(6) infinite;
    }
    .loader:before {
        content: 'Uploading File...';
    }
    @keyframes l3 {
        to {
            background-position: 80% 100%;
        }
    }
    .file-icon {
        height: 1.3rem;
    }
}
