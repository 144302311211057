.snackbar-main {
    &.SnackbarItem-variantSuccess {
        background-color: #22bca8 !important;
    }
    .SnackbarItem-message {
        display: flex;
        align-items: center;
        //    font-size: 12px !important;
        //     svg {
        //     font-size: 18px !important;
        //     }
    }
}
.drackathon-app {
    font-size: 10px;
    color: #383838;
    margin: 0;

    button {
        // color: red;
    }

    .title {
        font-size: 40px;
        font-weight: 700;
        font-family: 'Playfair Display', serif;
        color: #fff;
    }

    .black {
        color: #383838;
        font-weight: 400;
    }

    .btn {
        font-size: 14px;
        color: #383838;
        width: 170px;
        height: 50px;
        color: white;
        border: none;
        cursor: pointer;
    }

    .btn-green {
        background: #22bca8;
    }

    .btn-black {
        background: #383838;
    }

    .content .top-area .title {
        font-size: 30px;
        color: #22bca8;
    }

    .menubar {
        display: flex;
        justify-content: space-between;
        height: 100px;
        align-items: center;
        box-shadow: 0 3px 20px #e1e1e129;
        z-index: 999;
        position: relative;
        background: white;
    }

    .menubar .logo {
        margin-left: 140px;
        height: 100%;
    }
    .menubar .logo img {
        height: 100%;
    }

    .menubar .links {
        display: flex;
        justify-content: space-between;
        margin-right: 100px;
    }

    .menubar .links .link {
        font-size: 13px;
        padding: 5px 10px;
        font-weight: 500;
        margin-right: 10px;
        text-transform: uppercase;
    }

    .menubar .links .link a {
        display: block;
        text-decoration: none;
        color: black;
    }

    .menubar .links .dropdown {
        display: block;
        text-decoration: none;
        color: black;
        font-size: 13px;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
        border: none;
    }

    .menubar .links .dropdown .dropdown-content {
        display: block;
        text-decoration: none;
        color: black;
        font-size: 13px;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
        border: none;
    }
    .br-theme-bars-pill .br-widget {
        white-space: nowrap;
    }
    .br-theme-bars-pill .br-widget a {
        padding: 7px 15px;
        background-color: #bef5e8;
        color: #50e3c2;
        text-decoration: none;
        font-size: 13px;
        line-height: 3;
        text-align: center;
        font-weight: 400;
    }
    .br-theme-bars-pill .br-widget a:first-child {
        -webkit-border-top-left-radius: 999px;
        -webkit-border-bottom-left-radius: 999px;
        -moz-border-radius-topleft: 999px;
        -moz-border-radius-bottomleft: 999px;
        border-top-left-radius: 999px;
        border-bottom-left-radius: 999px;
    }
    .br-theme-bars-pill .br-widget a:last-child {
        -webkit-border-top-right-radius: 999px;
        -webkit-border-bottom-right-radius: 999px;
        -moz-border-radius-topright: 999px;
        -moz-border-radius-bottomright: 999px;
        border-top-right-radius: 999px;
        border-bottom-right-radius: 999px;
    }
    .br-theme-bars-pill .br-widget a.br-active,
    .br-theme-bars-pill .br-widget a.br-selected {
        background-color: #50e3c2;
        color: white;
    }
    .br-theme-bars-pill .br-readonly a {
        cursor: default;
    }
    .br-theme-bars-pill .br-readonly a.br-active,
    .br-theme-bars-pill .br-readonly a.br-selected {
        background-color: #7cead1;
    }
    @media print {
        .br-theme-bars-pill .br-widget a {
            border: 1px solid #b3b3b3;
            border-left: none;
            background: white;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }
        .br-theme-bars-pill .br-widget a.br-active,
        .br-theme-bars-pill .br-widget a.br-selected {
            border: 1px solid black;
            border-left: none;
            background: white;
            color: black;
        }
        .br-theme-bars-pill .br-widget a:first-child {
            border-left: 1px solid black;
        }
    }
}
